import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Footer from './components/Footer';
import About from './pages/About';
import Swiftshop from './pages/Swiftshop';
import ScrollToTop from './components/ScrollToTop';
import Resume from './pages/Resume';
import LillianAugusta from './pages/LillianAugusta';
import { Analytics } from "@vercel/analytics/react"
import JSTOR from './pages/JSTOR';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<>
            <Hero />
            <Projects />
          </>} />
          <Route path='/about' element={<About />} />
          <Route path='/swiftshop' element={<Swiftshop />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='/lillianaugusta' element={<LillianAugusta />} />
          <Route path='/jstor' element={<JSTOR />} />
        </Routes>
        <Footer />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;


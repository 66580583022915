import React from 'react'

const JSTOR = () => {
  return (
    <div className='text-center my-64'>
      <h1 className='font-bold text-[#AB0101] text-xl'>Under Construction, stay tuned!</h1>
    </div>
  )
}

export default JSTOR
import React from 'react'
import Carousel from '../components/Carousel';
import { Link } from 'react-router-dom';

const images = [
    '/lillian-content/kayla-p1.svg',
    '/lillian-content/jada-p2.svg'
];

const images2 = [
    '/lillian-content/journey-1.svg',
    '/lillian-content/journey-2.svg'
];

const images3 = [
    '/lillian-content/pitch-1.svg',
    '/lillian-content/pitch-2.svg',
    '/lillian-content/pitch-3.svg',
    '/lillian-content/pitch-4.svg',
    '/lillian-content/pitch-5.svg',
    '/lillian-content/pitch-6.svg',
    '/lillian-content/pitch-7.svg',
    '/lillian-content/pitch-8.svg',
    '/lillian-content/pitch-9.svg',
    '/lillian-content/pitch-10.svg',
    '/lillian-content/pitch-11.svg',
    '/lillian-content/pitch-12.svg',
    '/lillian-content/pitch-13.svg'
];



const LillianAugusta = () => {
  return (
    <div className='relative'>
        <div className='relative'>
            {/* background mobile image default */}
            <div className='md:hidden'>
                <img src='/lillian-content/bg-lillian-mobile.png' alt='header background' className='w-full h-auto max-w-full'/>
                <hr className="border-t border-gray-600" />
            </div>
            
            {/* larger screens background image */}
            <div className='hidden md:block absolute inset-0 md:z-0'>
                <img src='/lillian-content/bg-lillian-big.png' alt='header background' className='w-full h-screen max-w-full'/>
                <hr className="border-t border-gray-600" />
            </div>
            

            {/* overlay content */}
            <div className='absolute inset-0 md:relative md:z-10 flex flex-col items-center my-16 pt-12 md:flex-row md:items-start justify-center md:pt-20 lg:pt-44 md:mx-32'>
                {/* mobile mockup */}
                <img src='/lillian-content/lillian-cover.png' alt='lillian augusta cover' className='w-64 lg:w-80 md:w-1/2'/>
                {/* title + desc + grid */}
                <div className='text-left ml-6 md:ml-16 xl:mr-32'>
                    <div className='text-left ml-0'>
                        <h1 className='bg-gradient-to-r from-amber-950 to-rose-800 bg-clip-text text-transparent text-2xl mt-3'>Harmonizing Haircare: Unveiling Consumer Insights for Lillian Augusta's 'Hair Without Harm</h1>
                        <p className='text-gray-600 mt-2'>Exploration of customer journey research and market opportunities for a B2C process.</p>
                    </div>
                    <hr className="border-t border-gray-600 my-6" />
                    <div className='grid grid-cols-2 gap-6'>
                        <div>
                            <h1 className='uppercase text-lillian-black font-bold text-x mb-1 italic'>role</h1>
                            <ul className='text-lillian-black'>
                                <li>UX Researcher</li>
                                <li>Entrepreneur</li>
                            </ul>
                        </div>
                        <div>
                            <h1 className='uppercase text-lillian-black font-bold text-x mb-1 italic'>duration</h1>
                            <ul className='text-lillian-black'>
                                <li>Jan 2024-Apr 2024</li>
                            </ul>
                        </div>
                        <div>
                            <h1 className='uppercase text-lillian-black font-bold text-x mb-1 italic'>team</h1>
                            <ul className='text-lillian-black'>
                                <li>Fahmida Rahman</li>
                                <li>Emily You</li>
                                <li>Maddy Meyer</li>
                                <li>Ryan Bentley</li>
                                <li>Phoebe Oh</li>
                            </ul>
                        </div>
                        <div>
                            <h1 className='uppercase text-lillian-black font-bold text-x mb-1 italic'>tools</h1>
                            <ul className='text-lillian-black'>
                                <li>Figma (+ FigJam)</li>
                                <li>Adobe Illustrator</li>
                                <li>Canva</li>
                                <li>Miro</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className='pt-28 text-primary-red text-center text-3xl font-bold my-36'>
            <h1>Under Construction! Stay Tuned :D</h1>
        </div> */}

        {/* PROJECT OVERVIEW */}
        <div className='flex flex-col mx-6 md:flex-row md:mt-60 md:mx-10 md:space-x-16 lg:mt-64 lg:mx-40 xl:mx-64 xl:mt-80'>
            <h1 className='text-amber-900 font-semibold text-2xl italic pt-8'>Project Overview</h1>
            <div className='md:px-4'>
                <p className='text-p-black py-2 '>Lillian Augusta Beauty (LAB) is a startup based in Chicago, Illinois motivated to provide <b>hair without harm</b>, providing plant-based, allergen tested & vegan braiding hair. 
                </p>
                <p className='text-p-black py-2'>Throughout a semester during my SI 311: UX-Driven Entrepreneurship course, my team and I worked on a customer/market research study for this startup. Incorporating both <b>user research</b> knowledge and <b>entrepreneurship</b> skills, lots of final deliverables were created.</p>
                <p className='text-p-black py-2 '>However, we encountered multiple challenges and obstacles… read more about it below</p>
            </div>
        </div>

        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold py-6 italic'>MY User Journey of Completing this Project</h1>
            <img src='/lillian-content/my-user-journey.svg' alt='my-user-journey' className=''/>
        </div>

        {/* DIVIDER WITH GOALS */}
        <div className='bg-gradient-to-r from-[#D0A4A9] to-[#EDCDB3]'>
            <hr className="border-t border-gray-400" />
            <div className='mx-6 my-4 md:mx-10 md:my-10 lg:mx-40 xl:mx-64'>
                <p className='md:mr-48 mb-12'>Lillian Augusta Beauty (LAB) is a rising brand with a unique solution that is aiming to reach a larger market size and audience.</p>
                <p className='text-right md:ml-48'>But, they do not have research to back up <b>who</b> they should be reaching out to and <b>how</b> they should reach out to reach this goal.</p>
            </div>
            <hr className="border-t border-gray-400"/>
        </div>

        {/* GOALS */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold py-6 italic'>Goals for Us</h1>
            <p><b>1 /</b> Interview salons to gauge typical behaviors and experiences for the b2b market</p>
            <p><b>2 /</b> Create customer personas and a customer journey map </p>
            <p><b>3 /</b> Create a pitch deck for the startup to enhance branding and marketing </p>
            <div>
            </div>
        </div>

        {/* PRE DOWNFALL */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold py-6 italic'>Pre-Downfall</h1>
            <p>The original plan was to recruit and interview salons in the chicago + georgia are as this was lillian augusta’s target area.</p>
            <p>However, after compiling some preparations prior to interviewing, the scope was changed. 😧</p>
            <div>
                Materials gathered before the change:
                <ul className='list-disc ml-10'>
                    <li>Recruitment Plan</li>
                    <li>Recruitment Method</li>
                    <li>Interview Guide</li>
                    <li>Market Segmentation</li>
                </ul>
            </div>
        </div>

        {/* RE DIRECTION PHASE */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold py-6 italic'>🔔 Reminder: Ask Questions </h1>
            <p>Our team was a bit confused as to what direction this case study needed to go in so we changed our goals to general hair care issues and change the inclusion/exclusion criteria, interview guide, and interviews themselves to match this instead.</p>
            <p>However, once again, we needed to change this as well. 😔 Our interviews needed to be scrapped.</p>
            <p className='italic bg-yellow-100 mt-2'>Basically, remember to ask questions early on so that there is a solid foundation to work with before moving on with other phases of your research and design!</p>
        </div>


        {/* BACK ON TRACK PHASE */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold py-6 italic'>🔄 Circling back…</h1>
            <p>To ensure that the research being done was still useful to the client despite reduced involvement, research needed to be done again on new inclusion and exclusion criteria that was relevant to the company.</p>
            <p>While there were some difficulties recruiting, our team was able to manage and find overlapping themes and create insights from this analysis.</p>
            <p>Remember when we conducted interviews before getting redirected? Here are the revised inclusion/exclusion criteria of the interview participants—<span style={{ backgroundColor: '#FFF9C4' }}>visualized!</span></p>
            <img src='/lillian-content/revised-participants.png' alt='revised interviewees animojis' className='my-6'/>
        </div>

        {/* Recruit */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold py-6 italic'>Rapid Recruitment Needed</h1>
            <p className='my-2'>After all the changes, we needed to find new interview participants… and <b>fast!</b></p>
            <p>Before this, I never had to recruit for interviews that were demographic-based as well so the inclusion/exclusion criteria made it difficult to find people that would be fitting to the research.</p>
            <p className='my-2'>How did I do it? Personal connections. 😊</p>
            <p className='my-2'>You’d be surprised with how people are willing to help out if you just ask. It was definitely out of my comfort zone especially with those that I have not reached out to in a while but this opportunity allowed me to have great conversations.</p>
            <p className='my-2'>You may be wondering or questioning whether using personal connections would hinder the research at all because, as an interviewer, I already have a relationship with the participants. But, I find that as long as you compose yourself and conduct the interview in a professional yet comfortable manner, user insights are, just as, if not more valuable as regular ones because participants are <b>willing to share anything</b> without feeling the need to lie or conceal true feelings.</p>
        </div>

        {/* INTERVIEW PHASE */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold py-6 italic'>Voices of the Customers</h1>
            <p className='my-2'>Interviews were part of the next step and these were definitely not easy. However, I was taught best practices when being the interviewer—build rapport, use the funneling technique, and ask the participants to story tell.</p>
            <p className='my-2'>Interviews revealed a lot of new insights and information that I would not have known had I not interviewed people that had experiences of getting their har braided. From my initial secondary research, I was able to gauge the overall issues people face but not the details of anything.</p>
            <p className='my-2'>Here are some things I've learned:</p>
            <img src='/lillian-content/interview-insights.svg' alt='interview steps insights' className='my-6'/>
            <p className='italic my-2'>TL;DR Each interview just got better and better and new insights were revealed with probing questions. Practice leads to improvement (not perfection because anything can be refined).</p>
        </div>

        {/* CODES PHASE */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold py-6 italic'>Decoding Insights 🤓</h1>
            <p className='my-2'>Coding the interviews so that it was organized for mapping was a really difficult phase. The first time around, I received critiques on how I was coding for thematic analysis. However, after taking into account that in order to code the interviews, you shouldn’t: create too many different codes, cherry pick or have confirmation bias, and be literal (basically don’t simply rephrase).</p>
            
            <img src='/lillian-content/codes.svg' alt='interview codes' className='my-6'/>
            <p className='italic my-2'>TL;DR Each interview just got better and better and new insights were revealed with probing questions. Practice leads to improvement (not perfection because anything can be refined).</p>
        </div>

        {/* KEY FINDINGS */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold pt-6 italic'>Untangling Key Findings</h1>
        </div>
        <div className='bg-gradient-to-r from-[#D0A4A9] to-[#EDCDB3]'>
            <hr className="border-t border-gray-400" />
            <div className='flex flex-col mx-6 my-4 md:flex-row md:mx-10 md:my-10 lg:mx-40 xl:mx-64'>
                <div className='px-6 pb-4'>
                    <h2 className='font-semibold md:text-lg'>🔍 1 / Overall</h2>
                    <p className='md:pt-4'>Cost, natural look/feel, convenience, & social influence are significant factors for deciding which synthetic hair to buy</p>
                </div>
                <div className='px-6 pb-4'>
                    <h2 className='font-semibold md:text-lg'>✨ 2 / Effortless</h2>
                    <p className='font-medium md:pt-4'>83% of our interviewees value low maintenance hairstyles.</p>
                    <ul className='list-disc ml-8'>
                        <li>“I find it more convenient to have braids because it does not require additional styling (in the morning)”</li>
                        <li>“More protective braiding styles are usually little to no maintenance”</li>
                        <li>“My braids are more low maintenance and easy to manage when I oil them because humidity plays a factor”</li>
                    </ul>
                </div>
                <div className='px-6 pb-4'>
                    <h2 className='font-semibold md:text-lg'>🗣️📲 3 / Listen to this</h2>
                    <p className='font-medium md:pt-4'>93% of our interviewees rely on word-of-mouth recommendations and social media reviews.</p>
                    <ul className='list-disc ml-8'>
                        <li>“I usually rely on recommendations from friends and family or do some research online to learn more.”</li>
                        <li>“I normally get a recommendation or get inspiration from my friends.”</li>
                    </ul>
                </div>
            </div>
            <hr className="border-t border-gray-400" />
        </div>

        {/* PERSONAS PT 1 */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold pt-10 italic'>Meet the People..or Not</h1>
            <p className='my-2'>From our findings, my group and I worked on two personas that were typically seen throughout the interviews that were conducted. However, when we were doing a check-in with our progress and showed our current personas at this time, it was said that the personas did not seem distinct enough and that we should replace our concerns over demographics with actual customer goals, behaviors, and pain points instead. This led me to think:</p>
            <p className='italic text-amber-900'>What is the differentiating factor that drives each persona when handling a product like ours?</p>
        </div>

        {/* PERSONAS PT 2 */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold pt-10 italic'>Making the Personas Unique</h1>
            <p className='my-2'>From this, we were able to adjust our personas to be different from each other but chasing goals that were still relevant to the pain points identified in the participants of the interviews. One thing I really like and find helpful in creating personas and involving them in the research and design processes is giving them a title! This not only allows stakeholders to clearly understand the nature of the personas but it also helps me as a designer.</p>
            <p className='bg-[#EDCDB3]'>PRIMARY PERSONA: Influenced by social media, curious & cost-conscious</p>
            <p className='bg-[#EDCDB3]'>SECONDARY PERSONA: Eco-conscious consumer and willing to invest in hair quality</p>
            <div className='mx-6 my-4 md:mx-10'>
                <Carousel images={images} />
            </div>
        </div>

        {/* JOURNEY MAPS */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold pt-10 italic'>Tracing the Experience</h1>
            <p className='my-2'>Next, it was time to trace the journey of each persona and how their full experience plays out. This helps to visualize and understand the full customer experience, both on the designer and stakeholder side.</p>
            <div className='mx-6 my-4 md:mx-10'>
                <Carousel images={images2} />
            </div>
        </div>

        {/* PITCH DECK */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold pt-10 italic'>Entrepreneur-Mode On</h1>
            <p className='my-2'>As for the last part of our project, it’s not much about user experience but it’s very necessary in the process of product design and marketing. Our team created our own version of a pitch deck and presented it! It was very insightful to see how there can be overlapping concepts and skills from UX Design and Entrepreneurship. There is great value in learning and applying knowledge from different disciplines. Additionally, this project reinforced my understanding of how UX principles can be applied beyond traditional digital products. Here's our pitch deck:</p>
            <div className='mx-6 my-4 md:mx-10'>
                <Carousel images={images3} />
            </div>
        </div>

        {/* REFLECTIONS */}
        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold pt-10 italic'>My Review ⭐</h1>
            <p className='my-2'>Embarking on this project for a synthetic hair braiding startup was both exhilarating and daunting. With minimal exposure to the beauty industry, I found myself navigating an unfamiliar landscape filled with unique challenges and opportunities. Our initial B2B focus shifted to a B2C approach as the project progressed, compelling us to <b>reimagine our strategies and user engagement methods</b>—pushing me well beyond my comfort zone.</p>
            <p className='my-2'>We encountered numerous obstacles throughout the project, including tight deadlines and recruitment hurdles. The constant struggle to find participants who could offer valuable insights, coupled with looming deadlines, intensified the pressure. Aligning our research goals with the client's vision and timeline proved challenging, often leaving us uncertain about our direction.</p>
            <p className='my-2'>Yet, these hurdles taught me invaluable lessons in <b>resilience and adaptability</b>. Each obstacle forced us to reassess our strategies, underscoring the importance of flexibility in UX research. I also realized the crucial need to anchor our work in user insights, even when external factors threatened to derail our focus.</p>
            <p className='my-2'>This experience has been a profound growth opportunity for me as a designer. The project taught me to <b>embrace uncertainty</b> and develop more effective stakeholder engagement strategies. Given the project’s nature and target audience, there was no way for me to insert myself as a customer; hence, I found it particularly insightful to gather final insights based on valuable data with no assumptions.</p>
            <p className='my-2'>Reflecting on this journey, I recognize that the challenges we faced not only honed my problem-solving skills but also deepened my understanding of human-centered design principles. I'll carry these lessons into future projects, confident in my ability to navigate complexities and drive meaningful outcomes so I can thrive in agile environments.</p>
        </div>


        <div className='mx-6 mb-8 md:mx-10 lg:mx-40 xl:mx-64'>
            <h1 className='text-2xl text-amber-900 font-bold pt-10 italic'>Check Out My Other Work!</h1>
        </div>
        <div className='group flex flex-row lg:mx-40 xl:mx-64'>
            <Link to='/swiftshop' className='group h-full w-auto py-12 flex flex-col'>
            {/* image */}
            <div className='relative flex flex-col w-full overflow-hidden group items-center justify-center '>
                <img src='/projects/mockup-swiftshop.png'
                alt='swifsthop mockup iphone'
                className='relative w-2/3 h-full object-cover'/>
            </div>

            <div className='md:w-3/4 justify-between md:space-x-12'>
            <div className='flex items-start space-x-7 mt-4 mr-24 md:ml-12'>
                <img src='/projects/swiftshop-logo.png' alt='swiftshop logo' className='w-20' />
                <div className='pt-2 text-left'>
                {/* stack these two divs */}
                <div className='font-semibold mb-1 text-lg'>Swiftshop</div> 
                <div className='text-sm text-emphasis-gray truncate '>DESIGNER / PERSONAL PROJECT</div>
                </div>
            </div>
            {/* title + description */}
            <div className='text-left ml-0'>
                <h1 className='bg-gradient-to-r from-slate-700 to-slate-400 bg-clip-text text-transparent text-2xl mt-3'>Online Shopping Experiences Catered to a User</h1>
                <p className='text-emphasis-gray mt-2'>Making online shopping easier with a swipe left-to-right mechanism tailored to a user's preferences.</p>
            </div>
            </div>
            </Link>

            <Link to='/jstor' className='group h-full w-auto p-12 flex flex-col'>
            {/* image */}
            <div className='relative flex flex-col w-full h-[285px] overflow-hidden group items-center justify-center '>
                <img src='/jstor-content/jstor-cover.svg'
                alt='jstor mockup laptop'
                className='relative w-2/3 h-auto object-cover'/>
            </div>

            <div className='md:w-3/4 justify-between md:space-x-12'>
            <div className='flex items-start space-x-7 mt-4 mr-24 md:ml-12'>
                <img src='/jstor-content/jstor-logo.svg' alt='jstor logo' className='w-20' />
                <div className='pt-2 text-left'>
                {/* stack these two divs */}
                <div className='font-semibold mb-1 text-lg'>JSTOR</div> 
                <div className='text-sm text-emphasis-gray truncate '>DESIGNER & RESEARCHER / CLIENT PROJECT</div>
                </div>
            </div>
            {/* title + description */}
            <div className='text-left ml-0'>
                <h1 className='bg-gradient-to-r from-red-900 to-zinc-300 bg-clip-text text-transparent text-2xl mt-3'>User Odyssey: Enhancing Navigation for Endless Exploration to Academia</h1>
                <p className='text-emphasis-gray mt-2'>Giving Our Readers What They Want: It’s Time to Make the JSTOR Daily Website More Useful for Visitors.</p>
            </div>
            </div>
            </Link>
        </div>

    </div>
    
  )
}

export default LillianAugusta
import React from 'react';
import { Link } from 'react-router-dom';

const Projects = () => {
  return (
    <div>
      <h1 className='text-center pt-14 text-primary-red font-serif italic font-bold text-2xl'>PROJECTS</h1>

      {/* whole project grid container*/}
      <div id="projects" className='flex flex-col md:mx-20 lg:mx-48'>
        {/* individual project container */}
        {/* SWIFTSHOP */}
        <Link to='/swiftshop' className='group h-full w-auto p-12 flex flex-col md:flex-row'>
          {/* image */}
          <div className='relative flex flex-col w-full overflow-hidden group md:w-1/2 items-center justify-center '>
            <img src='/projects/ellipse-behind-1.png'
            alt='gradient'
            className='absolute inset-0 object-cover transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 transform -translate-y-0  group-hover:translate-y-[20%]'/>

            <img src='/projects/mockup-swiftshop.png'
            alt='swifsthop mockup iphone'
            className='relative w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:translate-y-[-10%]'/>
          </div>

          <div className='md:w-3/4 justify-between md:space-x-12'>
          <div className='flex items-start space-x-7 mt-4 mr-24 md:ml-12'>
            <img src='/projects/swiftshop-logo.png' alt='swiftshop logo' className='w-20' />
            <div className='pt-2 text-left'>
            {/* stack these two divs */}
              <div className='font-semibold mb-1 text-lg'>Swiftshop</div> 
              <div className='text-sm text-emphasis-gray truncate '>DESIGNER / PERSONAL PROJECT</div>
            </div>
          </div>
          {/* title + description */}
          <div className='text-left ml-0'>
            <h1 className='bg-gradient-to-r from-slate-700 to-slate-400 bg-clip-text text-transparent text-2xl mt-3'>Online Shopping Experiences Catered to a User</h1>
            <p className='text-emphasis-gray mt-2'>Making online shopping easier with a swipe left-to-right mechanism tailored to a user's preferences.</p>
          </div>
          {/* tags container */}
          <div className='flex flex-row flex-wrap mt-2'>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              Product Design
            </div>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              UX Research
            </div>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              Wireframing
            </div>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              Brand & Visual Identity
            </div>
          </div>
          </div>
          </Link>

          {/* LILLIAN AUGUSTA */}
          <Link to='/lillianaugusta' className='group h-full w-auto p-12 flex flex-col md:flex-row-reverse'>
          {/* image */}
          <div className='relative flex flex-col w-full overflow-visible group md:w-1/2 items-center justify-center '>
            <img src='/lillian-content/ellipse-2.png'
            alt='gradient'
            className='absolute inset-0 object-cover transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 transform -translate-y-0  group-hover:translate-y-[-10%]'/>

            <img src='/lillian-content/lillian-cover.png'
            alt='lillian augusta cover'
            className='relative rounded-md w-64 h-auto object-cover transition-transform duration-300 ease-in-out group-hover:translate-y-[-10%]'/>
          </div>

          <div className='md:w-3/4 justify-between md:space-x-12'>
          <div className='flex items-start space-x-7 mt-4 mr-24 md:ml-12'>
            <img src='/lillian-content/lab-logo.png' alt='Lillian Augusta logo' className='w-20 rounded-lg' />
            <div className='pt-2 text-left'>
            {/* stack these two divs */}
              <div className='font-semibold mb-1 text-lg'>Lillian Augusta Beauty</div> 
              <div className='text-sm text-emphasis-gray truncate '>RESEARCHER / CLIENT PROJECT</div>
            </div>
          </div>
          {/* title + description */}
          <div className='text-left ml-0'>
            <h1 className='bg-gradient-to-r from-amber-950 to-orange-200 bg-clip-text text-transparent text-2xl mt-3'>Harmonizing Haircare: Unveiling Consumer Insights for Lillian Augusta's 'Hair Without Harm</h1>
            <p className='text-emphasis-gray mt-2'>Exploration of customer journey research and market opportunities for a B2C process.</p>
          </div>
          {/* tags container */}
          <div className='flex flex-row flex-wrap mt-2'>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              UX Research
            </div>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              Market Research
            </div>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              Entrepreneurship
            </div>
          </div>
          </div>
          </Link>

          {/* JSTOR */}
          <Link to='/jstor' className='group h-full w-auto p-12 flex flex-col md:flex-row'>
          {/* image */}
          <div className='relative flex flex-col w-full overflow-visible group md:w-1/2 items-center justify-center '>
            <img src='/jstor-content/star-back.svg'
            alt='gradient'
            className='absolute inset-0 object-cover transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 transform -translate-y-0  group-hover:translate-y-[-20%]'/>

            <img src='/jstor-content/jstor-cover.svg'
            alt='jstor cover'
            className='relative rounded-md w-96 h-auto object-cover transition-transform duration-300 ease-in-out group-hover:translate-y-[-20%]'/>
          </div>

          <div className='md:w-3/4 justify-between md:space-x-12'>
          <div className='flex items-start space-x-7 mt-4 mr-24 md:ml-12'>
            <img src='/jstor-content/jstor-logo.svg' alt='JSTOR logo' className='w-20 rounded-lg' />
            <div className='pt-2 text-left'>
            {/* stack these two divs */}
              <div className='font-semibold mb-1 text-lg'>JSTOR Daily</div> 
              <div className='text-sm text-emphasis-gray truncate '>DESIGNER & RESEARCHER / CLIENT PROJECT</div>
            </div>
          </div>
          {/* title + description */}
          <div className='text-left ml-0'>
            <h1 className='bg-gradient-to-r from-red-900 to-zinc-300 bg-clip-text text-transparent text-2xl mt-3'>User Odyssey: Enhancing Navigation for Endless Exploration to Academia</h1>
            <p className='text-emphasis-gray mt-2'>Giving Our Readers What They Want: It’s Time to Make the JSTOR Daily Website More Useful for Visitors.</p>
          </div>
          {/* tags container */}
          <div className='flex flex-row flex-wrap mt-2'>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              UX Research
            </div>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              UX Design
            </div>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              Wireframing
            </div>
            <div className='truncate text-center rounded-full p-2 px-4 mr-2 bg-low-emphasis-gray text-xs text-p-black mb-2'>
              Usability Evaluation
            </div>
          </div>
          </div>
          </Link>

        </div>
      </div>

  )
}



export default Projects